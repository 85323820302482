<template>
	<div>
		<h2>{{ $t("trigger.mes_declencheurs") }}</h2>
		<div class="box">
			<CustomTable
				id_table="trigger_entity"
				ref="tableTrigger"
				:items="filtredTriggers"
				:busy.sync="table_busy"
				primaryKey="triggerentity_id"
				:hide_if_empty="true"
				:externSlotColumns="['active', 'global']"
			>
            	<template v-slot:[`custom-slot-cell(active)`]="{ data }">
					<div class="custom-control custom-checkbox">
						<b-form-checkbox 
							name="check-button"
                            switch 
							v-model="data.switch" 
							@change="switchTrigger(data.triggerentity_id, data.switch)"
						>
							<template v-if="data.switch">
								{{ $t('trigger.actif') }}
							</template>
							<template v-else>
								{{ $t('trigger.inactif') }}
							</template>
						</b-form-checkbox>
					</div>
				</template>
				<template v-slot:[`custom-slot-cell(global)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.global" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
            </CustomTable>
		</div>
	</div>
</template>


<script type="text/javascript">

	import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import { EventBus } from 'EventBus';
	import Triggers from "@/mixins/Triggers.js"

	export default {
		name: "listTrigger",
		mixins: [Navigation, Config, TableAction, Triggers],
		props: [],
		data () {
			return {
				triggers: null,
				table_busy: true,
				events_tab: {
				    'TableAction::goToEditTrigger': (params) => {
				    	this.$router.push({name: "triggersEdit", params: { triggerentity_id: params.triggerentity_id }})
				    },
				    'TableAction::goToAddTrigger': (params) => {
				    	this.$router.push({ name: "triggersAdd" })
				    },
				    'TableAction::goToDeleteTrigger': this.deleteTrigger,
				},
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loadtriggers()
			},

			async loadtriggers() {
				this.table_busy = true
				let triggers = await this.loadTriggerEntity()
				this.triggers = triggers.map(trigger => {
					trigger.switch = trigger.active
					trigger.id = trigger.triggerentity_id
					return trigger
				})
                this.table_busy = false
			},

			async switchTrigger(id, val) {
				let res = await this.saveActiveTrigger(id, !val)
				if(res) {
					this.successToast("toast.info_save_succes")
				}
			},

			async deleteTrigger(triggers) {
				// si trigger global et pas admin => erreur
				let user = await this.getUserAccessRights()
				const trigger_global = triggers.filter(t => t.global === true)
				if(trigger_global.length > 0 && user.indexOf("ADMN") == -1) {
                	this.failureToast('trigger.modif_trigger_global');
                	return false
                }

				let tab_promises = []
				for(let i in triggers) {
					tab_promises.push(this.deleteTriggerEntity(triggers[i].triggerentity_id))
				}

				Promise.all(tab_promises)
				.then(() => {
					this.init_component()
					this.$refs.tableTrigger.resetCachedItems() 
					this.successToast("toast.info_save_succes")
				})
				.catch(() => {
					this.failureToast()
				})
			}
		},
		computed: {
			filtredTriggers: function() {
				let triggersFormated = []

				if(!this.triggers) {
					return []
				}

			    for (var i = 0; i < this.triggers.length; i++) {
			        const temp = this.deepClone(this.triggers[i])
			        temp.trigger_entity_type.triggerentitytype_label = this.getTrad('trigger.entity_type.'+temp.trigger_entity_type.triggerentitytype_label)
			        temp.triggered_entity_type.triggeredentitytype_label = this.getTrad('trigger.entity_type.'+temp.triggered_entity_type.triggeredentitytype_label)

					switch(this.$i18n.locale()) {
						case 'fr':
							temp.name_declencheur = temp.period.map(rep => rep.triggeredperiod_label).join(', ')
							break;
						case 'en':
							temp.name_declencheur = temp.period.map(rep => rep.triggeredperiod_label_en).join(', ')
							break;
						default:
							temp.name_declencheur = temp.period.map(rep => rep.triggeredperiod_label).join(', ')
					}

			        triggersFormated.push(temp);
			    }

				return triggersFormated
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}
</script>
